import { ServicePayment, OfferedAsType } from '@wix/bookings-uou-types';
import { PaidPlans, Plan } from '@wix/ambassador-checkout-server/types';
import {
  isServiceOfferedAsPricingPlan,
  PaymentDtoMapper,
} from '@wix/bookings-uou-mappers';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
  TFunction,
} from '../../types/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { getContent } from '../content/content';
import settingsParams from '../../components/BookingsForm/settingsParams';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const isOfferedAsOneTime = (servicePayment: ServicePayment) => {
  return servicePayment.offeredAs.includes(OfferedAsType.ONE_TIME);
};

export const isOfferedAsOneTimeOnly = (servicePayment: ServicePayment) => {
  return (
    servicePayment.offeredAs.length === 1 && isOfferedAsOneTime(servicePayment)
  );
};

export const isOfferedAsPricingPlan = (servicePayment: ServicePayment) => {
  return servicePayment.offeredAs.includes(OfferedAsType.PRICING_PLAN);
};

export const isOfferedAsPricingPlanOnly = (servicePayment: ServicePayment) => {
  return (
    servicePayment.offeredAs.length <= 1 &&
    isServiceHavePricingPlans(servicePayment)
  );
};

export const isFixedPrice = (servicePayment: ServicePayment) => {
  return servicePayment.paymentDetails.price > 0;
};

export const isCustomPrice = (servicePayment: ServicePayment) => {
  return !!servicePayment.paymentDetails.priceText;
};

export const isFreeService = (servicePayment: ServicePayment) => {
  return (
    !isFixedPrice(servicePayment) &&
    !isOfferedAsPricingPlan(servicePayment) &&
    !isCustomPrice(servicePayment)
  );
};

export const hasPurchasedPlans = (pricingPlanDetails?: PaidPlans) => {
  return Number(pricingPlanDetails?.plans?.length) > 0;
};

export const hasMoreParticipantsThenCredit = (
  creditRemain: number,
  numberOfParticipants: number,
) => {
  return creditRemain < numberOfParticipants;
};

export const isServiceHavePricingPlans = (servicePayment: ServicePayment) => {
  return servicePayment.pricingPlanInfo?.pricingPlans?.length! > 0;
};

const isDisabledPlan = ({
  plan,
  numberOfParticipants,
}: {
  plan: Plan;
  numberOfParticipants: number;
}) => {
  const isMembershipPlan = !plan.creditRemain;
  return (
    Number(plan?.creditRemain) < numberOfParticipants ||
    (isMembershipPlan && numberOfParticipants > 1)
  );
};

export const getPaymentOptions = ({
  pricingPlanDetails,
  servicePayment,
  t,
  settings,
  dateAndTimeFormatter,
  isPricingPlanInstalled,
  numberOfParticipants,
  dateRegionalSettingsLocale,
}: {
  pricingPlanDetails?: PaidPlans;
  servicePayment: ServicePayment;
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  dateAndTimeFormatter: DateTimeFormatter;
  isPricingPlanInstalled: boolean;
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
}): PaymentOption[] => {
  if (!servicePayment) {
    return [];
  }

  const pricingPlans =
    pricingPlanDetails?.plans?.map((plan) => {
      const disabled = isDisabledPlan({ plan, numberOfParticipants });
      return {
        id: plan.paidPlan?.orderId!,
        label: plan.planName!,
        disabled,
        ...(plan.creditRemain && plan.creditOriginal
          ? { suffix: plan.creditRemain + '/' + plan.creditOriginal }
          : {}),
        ...(plan.validUntil
          ? {
              validUntil: t('app.payment.valid-until.text', {
                validUntil: dateAndTimeFormatter.formatDate(plan.validUntil),
              }),
            }
          : {}),
        ...(plan.creditRemain ? { creditRemain: plan.creditRemain } : {}),
      };
    }) || [];

  const showBuyAPricingPlan =
    isServiceOfferedAsPricingPlan(servicePayment, isPricingPlanInstalled) &&
    pricingPlans.length === 0;
  const showPaySingleSession = isOfferedAsOneTime(servicePayment);

  return [
    ...pricingPlans,
    ...(showBuyAPricingPlan
      ? [
          {
            id: ReservedPaymentOptionIds.BuyAPricingPlan,
            label: getContent({
              settings,
              settingsParam: settingsParams.pricingPlanText,
            }),
            disabled: false,
          },
        ]
      : []),
    ...(showPaySingleSession
      ? [
          {
            id: ReservedPaymentOptionIds.SingleSession,
            ...getPriceText(
              servicePayment,
              dateRegionalSettingsLocale,
              settings,
            ),
            disabled: false,
          },
        ]
      : []),
  ];
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
  settings: ControllerFlowAPI['settings'],
) => {
  const price = new PaymentDtoMapper(dateRegionalSettingsLocale).priceText(
    payment.paymentDetails,
  );
  if (payment.paymentDetails.priceText) {
    return {
      label: price,
    };
  }
  return {
    label: getContent({
      settings,
      settingsParam: settingsParams.singleSessionText,
    }),
    suffix: price,
  };
};
